
import { Vue } from "vue-class-component";
import AMapLoader from "@amap/amap-jsapi-loader";
export default class LoctionPhotoDemo extends Vue {
  private wx = require("weixin-js-sdk");
  private map: any;
  private appID = process.env.VUE_APP_WXID;
  private appSecret = process.env.VUE_APP_WXSECRET;
  private locationStr = "";
  public created() {
    this.initWx();
    //this.initMap();
  }
  private msg = ""; //临时提示变量，后期删除
  public initWx() {
    //判断本地是否已存openID
    if (!this.$store.state.openId) {
      //如果未存，则要通过授权，回调页面，获取code，然后获取openID，保存本地
      this.getWxAuth();
    } else {
      //初始化wx的jssdk的config
      this.initWxConfig(this.$store.state.openId);
    }
  }
  //用户授权，回调，获取openID
  private getWxAuth() {
    //官方参考文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html
    if (!this.$route.query.code) {
      //微信授权，授权后重定向到本页面
      const localUrl = window.location.href;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appID}&redirect_uri=${localUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
    } else {
      //如果已经授权，获取code参数，通过后端获取openID，返回前端，保存本地缓存
      const url = "/public/wxPort/getWxOpenId";
      this.axios
        .get(url, {
          params: {
            code: this.$route.query.code,
            appID: this.appID,
            appSecret: this.appSecret,
          },
        })
        .then((res: any) => {
          if (res.status.code === 1) {
            //openID保存本地
            // this.setOpenID(res.data.openId);
            //初始化wx的jssdk的config
            this.initWxConfig(res.data.openId);
            this.msg += `---授权成功，openID:${res.data.openId}\n`;
          } else {
            //抛出错误
            this.msg += `---获取openID失败:${JSON.stringify(res)}\n`;
          }
        })
        .catch((err: any) => {
          this.msg += `---获取openID失败err:${JSON.stringify(err)}\n`;
        });
    }
  }
  //初始化wx JSSDK
  private initWxConfig(openId: string) {
    //后端获取access_token和ticket，返回签名信息，初始化wx.config
    const url = "/public/wxPort/getTicket";
    this.axios
      .get(url, {
        params: {
          url: window.location.href.split("#")[0],
          openId: openId,
          appID: this.appID,
          appSecret: this.appSecret,
        },
      })
      .then((res: any) => {
        if (res.status.code === 1) {
          this.msg += `---获取 ticket成功，返回结果:${JSON.stringify(
            res.data
          )}\n`;

          //官方参考文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#1
          //JSSDK签名验证： https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#62
          //初始化验证jssdk
          this.msg += JSON.stringify({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: this.appID, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["getLocation", "chooseImage", "getLocalImgData"], // 必填，需要使用的JS接口列表
          });
          this.wx.config({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: this.appID, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["getLocation", "chooseImage", "getLocalImgData"], // 必填，需要使用的JS接口列表
          });

          //通过ready接口处理成功验证
          this.wx.ready(() => {
            this.msg += `---初始化wx.config成功\n`;
            this.wx.checkJsApi({
              jsApiList: ["getLocation", "chooseImage", "getLocalImgData"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: (res: any) => {
                // 以键值对的形式返回，可用的api值true，不可用为false
                this.msg += `---检查wx.checkJsApi成功：${JSON.stringify(
                  res
                )}}\n`;
              },
            });
          });

          //通过error接口处理失败验证
          this.wx.error((err: any) => {
            this.msg += `---wx接口失败：${JSON.stringify(err)}}\n`;
            // this.$toast.fail("微信接口失败：请刷新再试！");
            location.reload();
          });
        } else {
          //抛出错误
          this.msg += `---获取ticket失败，返回结果:${JSON.stringify(res)}\n`;
        }
      })
      .catch((err: any) => {
        this.msg += `---获取ticket失败err，返回结果:${JSON.stringify(err)}\n`;
      });
  }
  private getLoacation() {
    const _that = this;
    this.wx.getLocation({
      type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (res: any) {
        //alert(JSON.stringify(res));
        console.log(res);
        _that.locationStr = JSON.stringify(res);
        const latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        const longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        console.log(latitude);

        const speed = res.speed; // 速度，以米/每秒计
        const accuracy = res.accuracy; // 位置精度
        AMapLoader.load({
          key: "6321d9f2385b2124ea0e3c4802030fc5", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              zoom: 11, //缩放级别
              center: [longitude, latitude], //中心点坐标
              viewMode: "3D", //使用3D视图
            });

            // 创建一个 Marker 实例：
            const marker = new AMap.Marker({
              position: new AMap.LngLat(longitude, latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: "北京",
            });
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marker);

            ///-----------------------------
            AMap.plugin("AMap.Geocoder", function () {
              const geocoder = new AMap.Geocoder({
                radius: 1000,

                extensions: "all",
              });

              geocoder.getAddress(
                [longitude, latitude],
                function (status, result) {
                  if (status === "complete" && result.info === "OK") {
                    _that.locationStr=result.regeocode.formattedAddress;
                  } else {
                    console.error("无地址，请重新选择");
                  }
                }
              );
            });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  }

  private takePhoto() {
    const _that = this;
    this.wx.chooseImage({
      count: 1, // 默认9
      sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res: any) {
        _that.getLocalImgData(res.localIds[0].toString());
      },
    });
  }

  private getLocalImgData(localId: string) {
    //获取本地图片
    this.wx.getLocalImgData({
      localId: localId,
      success: function (resL: any) {
        const localData = resL.localData;
        let imageBase64 = "";
        if (localData.indexOf("data:image") == 0) {
          //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
          imageBase64 = localData;
        } else {
          //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
          //此时一个正常的base64图片路径就完美生成赋值到img的src中了
          imageBase64 =
            "data:image/jpeg;base64," + localData.replace(/\n/g, "");
        }
        console.log(imageBase64);
      },
    });
  }

  //  private marker :any;
  // private initMap() {
  //   AMapLoader.load({
  //     key: "6321d9f2385b2124ea0e3c4802030fc5", // 申请好的Web端开发者Key，首次调用 load 时必填
  //     version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  //     plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  //   })
  //     .then((AMap) => {
  //       this.marker = AMap;
  //       this.map = new AMap.Map("container", {
  //         zoom: 11, //缩放级别
  //         center: [116.397428, 39.90923], //中心点坐标
  //         viewMode: "3D", //使用3D视图
  //       });

  //       // 创建一个 Marker 实例：
  //       const marker = new AMap.Marker({
  //         position: new AMap.LngLat(116.397428, 39.90923), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
  //         title: "北京",
  //       });
  //       // 将创建的点标记添加到已有的地图实例：
  //       this.map.add(marker);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }
}
